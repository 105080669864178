import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useTranslation } from "react-i18next";
import { useUserStripeData } from "hooks/useUserStripeData";
import { GatedContentStatus } from "hooks/useStripeAccountInfo";
import { Spinner } from "components/spinners/Spinner";
import { useLocation } from "react-router-dom";
import { useInterval } from "hooks/useInterval";

export const StripeAuthorizePage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    /** stripe is appending stuff to the end of the url that messes up our params */
    let redirectURL = params.get("returnUrl")?.split("?status")[0] ?? "";

    const [error, setError] = useState<boolean>(false);
    const [shouldPollStripe, setShouldPollStripe] = useState<boolean>(true);
    const [notificationDispatched, setNotificationDispatched] =
        useState<boolean>(false);

    const { accountData, getStripeAccounts } = useUserStripeData({
        requestImmediately: true,
        includeProducts: false,
        redirectPath: redirectURL
    });

    /** only poll stripe for ten seconds total */
    setTimeout(() => {
        setShouldPollStripe(false);
    }, 10000);

    /** if we dont have the data yet, continue making call to get stripe accounts */
    useInterval(() => {
        if (shouldPollStripe) getStripeAccounts();
    }, 3500);

    /** if, after 10 seconds, stripe isn't connected, throw error */
    useEffect(() => {
        if (
            !shouldPollStripe &&
            !(accountData?.gatedContentStatus === GatedContentStatus.READY)
        )
            setError(true);
    }, [shouldPollStripe, accountData.gatedContentStatus, accountData]);

    const stripeConnected =
        accountData?.gatedContentStatus === GatedContentStatus.READY;

    const userStripeLink = accountData?.link?.href;

    const errorMessage = useMemo(() => {
        if (stripeConnected) return;
        switch (accountData?.gatedContentStatus) {
            /** Details need to be submitted or charges are not enabled */
            case GatedContentStatus.STRIPE_ACCOUNT_UNVERIFIED:
                return t("gated-content-page:errors:need-more-details");
            /** Account not setup yet, show generic error message */
            case GatedContentStatus.STRIPE_ACCOUNT_NOT_SETUP:
                return t("gated-content-page:errors:generic-connection-error");
            default:
                return t("gated-content-page:errors:generic-connection-error");
        }
    }, [accountData?.gatedContentStatus, t, stripeConnected]);

    useEffect(() => {
        if (notificationDispatched) return;
        if (!error && stripeConnected) {
            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "gated-content-page:successfully-linked",
                    dismissible: false,
                    fadeMilliseconds: -1
                })
            );
            setNotificationDispatched(true);
        } else if (error && !shouldPollStripe) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: errorMessage,
                    linkComponents: [
                        {
                            key: "stripeLink",
                            to: userStripeLink,
                            title: errorMessage,
                            onClick: () =>
                                (window.location.href = userStripeLink)
                        }
                    ],
                    dismissible: false,
                    fadeMilliseconds: -1
                })
            );
            setNotificationDispatched(true);
        }
    }, [
        error,
        stripeConnected,
        dispatch,
        errorMessage,
        userStripeLink,
        notificationDispatched,
        shouldPollStripe
    ]);

    return (
        <>
            {shouldPollStripe && !error && !stripeConnected && (
                <>
                    <Spinner stroke={"#fff"} />
                </>
            )}
            {(stripeConnected || error) && (
                <a
                    className="m-auto d-flex justify-content-center btn btn-primary btn-block btn-lg"
                    href={redirectURL ?? "/gated-content"}
                >
                    {t("gated-content-page:continue")}
                </a>
            )}
        </>
    );
};
