import { VideoDownloadStatus } from "components/recording-card/RecordingCard/types";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { download } from "helpers/download";
import { startPollingDownload } from "store/downloads/thunks";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { useSwitcherClient } from "./useSwitcherClient";
import { useTranslation } from "react-i18next";
import rollbar from "helpers/rollbar";

export interface DownloadProps {
    broadcastId: string;
    videoId: string;
    title: string;
}

/**
 * Hooks into download state it initiate and monitor the state of processing downloads.
 */
export const useVideoDownload = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { pending, ready, pendingDownload, downloadProgress } = useSelector(
        (state: RootState) => state.downloads
    );

    const { dispatchApiRequest: startDownload } = useSwitcherClient(
        (client) => client.broadcasts_StartVideoDownload,
        {
            hideLoading: true
        }
    );

    const getDownloadProgress = useCallback(
        (downloadProps: DownloadProps) => {
            return downloadProgress[downloadProps.videoId];
        },
        [downloadProgress]
    );

    const getDownloadStatus = useCallback(
        (downloadProps: DownloadProps) => {
            if (
                pending.filter((dl) => dl.videoId === downloadProps.videoId)
                    .length > 0
            )
                return VideoDownloadStatus.InProgress;
            if (
                pendingDownload.filter(
                    (dl) => dl.videoId === downloadProps.videoId
                ).length > 0
            )
                return VideoDownloadStatus.InProgress;
            if (
                ready.filter((dl) => dl.videoId === downloadProps.videoId)
                    .length > 0
            )
                return VideoDownloadStatus.Ready;

            return VideoDownloadStatus.NotStarted;
        },
        [pending, ready, pendingDownload]
    );

    const handleDownload = useCallback(
        async (downloadProps: DownloadProps) => {
            const { broadcastId, videoId, title } = downloadProps;

            // if ready, download instead
            // should be disabled in the interim
            try {
                const response = await startDownload([videoId, broadcastId]);
                const downloadStatus = response.result?.default?.status;
                const downloadUrl = response.result?.default?.url;
                if (
                    !response.result ||
                    !response.success ||
                    response.errors?.length > 0 ||
                    downloadStatus === VideoDownloadStatus.Error
                ) {
                    throw new Error("Cloudflare returned error.");
                } else if (downloadStatus === VideoDownloadStatus.Ready) {
                    // no polling if the video happens to already be ready (like if it was downloaded in a prev session)
                    download(downloadUrl, title);
                } else {
                    dispatch(startPollingDownload(downloadProps));
                }
            } catch (e) {
                rollbar.error("Error downloading video", e);

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:download")
                    })
                );
            }
        },
        [dispatch, startDownload, t]
    );

    const handleDownloads = useCallback(
        async (downloadPropSet: DownloadProps[]) => {
            for (const downloadProps of downloadPropSet) {
                await handleDownload(downloadProps);
            }
        },
        [handleDownload]
    );

    return {
        /** Initiates downloading for a given broadcast. If download is ready, the download starts immediately, if it needs to be processed then it will poll until it is ready and start downloading. */
        handleDownload,
        /** Initiates download for an array of broadcasts */
        handleDownloads,
        /** Gets state of broadcast */
        getDownloadStatus,
        /** Gets the percentage of the progress of the download */
        getDownloadProgress,
        /** List of broadcasts that are ready for download. Broadcasts are added to this state after polling. */
        ready,
        /** List of broadcasts that are pending download. Broadcasts that are ready are moved from this list to ready. */
        pending
    };
};
