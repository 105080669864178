import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TextInput } from "components/inputs/text-input/TextInput";
import { TextArea } from "components/inputs/textarea/Textarea";
import { RootState } from "store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "store/store";
import {
    createFacebookStream,
    updateFacebookStream,
    createYouTubeEvent,
    updateYoutubeEvent,
    updateTwitchChannel,
    updateVideoPlayerStream
} from "store/platforms/thunks";
import styles from "./SimulcastForm.module.scss";
import {
    updateGeneralForm,
    updateFormState,
    updateSelectedStream,
    resetGeneralForm,
    updateQuality
} from "store/platforms/slice";
import { GeneralPlatformForm, FacebookProfile } from "store/platforms/types";
import { QualitySelector } from "components/quality-selector/QualitySelector";
import { FormWrapper } from "../forms/FormWrapper";
import { SimulcastDestinationDisplay } from "./simulcast-destinations/simulcast-destination-display/SimulcastDestinationDisplay";
import { PlatformId, PlatformInitialState } from "../../types";
import { FacebookForm } from "../facebook/FacebookForm";
import { YoutubeForm } from "../youtube/YouTubeForm";
import { TwitchForm } from "../twitch/TwitchForm";
import { VideoPlayerForm } from "../videoplayer/VideoPlayerForm";
import { useNavigate } from "react-router-dom";
import {
    Broadcast,
    CreatorProductEntitlementsBindingModelDiscriminator,
    SimulcastSwitcherStreamSetting
} from "@switcherstudio/switcher-api-client";
import { client } from "api/client";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { setLoading } from "store/loading/slice";
import { switcherSdk } from "utils/switcher-sdk";
import { ConditionalScheduling } from "components/conditional-scheduling/ConditionalScheduling";
import { ScheduledOptions } from "views/page-content/platforms/platform/forms/types";
import Accordion from "react-bootstrap/Accordion";
import FacebookIcon from "assets/icons/facebook.svg?react";
import YouTubeIcon from "assets/icons/youtube.svg?react";
import SwitcherLogo from "assets/icons/switcher-player.svg?react";
import { titleCase } from "utils/js-utils";
import { SimulcastModal } from "./simulcast-modal/SimulcastModal";
import { DestinationDescriptor } from "./types";
import { Toggle } from "components/inputs/toggle/Toggle";
import { useGetProjectsVideoPlayer } from "hooks/useGetProjectsVideoPlayer";
import dayjs from "dayjs";
import { useUserStatus } from "hooks/useUserStatus";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useSimulcastFork } from "hooks/useSimulcastFork";
import { ChannelSetting } from "../platform-helper";
import { SpeedTest } from "components/speed-test/SpeedTest";
import { usePlatformActions } from "hooks/usePlatformActions";
import { trackEvent } from "helpers/analyticsHelpers";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { useThumbnailUpload } from "hooks/useThumbnailUpload";
import { useSettableMemo } from "hooks/useSettableMemo";

interface SimulcastFormProps {}
export interface CompleteRequest {
    destination: DestinationDescriptor;
    status: "fulfilled" | "rejected";
    reason: { message: any };
}

export const SimulcastForm: React.FC<SimulcastFormProps> = () => {
    const [showModal, setShowModal] = useState(false);
    const [completedRequests, setCompletedRequests] = useState<
        CompleteRequest[]
    >([]);
    const [simulcastSetting, setSimulcastSetting] =
        useState<SimulcastSwitcherStreamSetting>({});
    const [allPostsFailed, setAllPostsFailed] = useState(false);
    const { general, videoplayer } = useSelector(
        (state: RootState) => state.platforms
    );
    const user = useSelector((state: RootState) => state.user);
    const { generalForm, formState, selectedStreams } = general;
    const { handleUploadThumbnail } = useThumbnailUpload(
        generalForm.scheduledImageFile,
        generalForm.scheduledImageFileUrl
    );
    const { t } = useTranslation();
    const { simulcastType, t: simulcastT } = useSimulcastFork();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const enablePayPerView = useClaimCheck("gatedcontent");
    const enableVLH = useClaimCheck("vlh");
    const enableAutosave = useClaimCheck("autosave");

    const isShopifyCustomer =
        user?.userInfo?.FeatureClaims?.includes("shopify");
    const { projects } = useGetProjectsVideoPlayer(
        user?.userInfo?.ProjectId,
        isShopifyCustomer
    );

    const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useSettableMemo(
        () =>
            general.formState === ScheduledOptions.Later &&
            general.generalForm.isInvalid,
        [general.formState, general.generalForm.isInvalid]
    );
    const { isInBrowser } = useSwitcherSdk();

    const { userPlanIsTrial } = useUserStatus();
    const hasVideoPlayerClaim = useClaimCheck("videoplayer");
    const hasCatalogClaim = useClaimCheck("catalog");

    const hasVideoPlayerAccess = useMemo(() => {
        return !userPlanIsTrial && hasVideoPlayerClaim;
    }, [userPlanIsTrial, hasVideoPlayerClaim]);

    const { init: facebookInit } = usePlatformActions(PlatformId.Facebook);
    const { init: youtubeInit } = usePlatformActions(PlatformId.Youtube);

    const ytDests = useMemo(
        () =>
            generalForm.selectedSimulcastDestinations.filter(
                (d) => d.platform === PlatformId.Youtube
            ),
        [generalForm.selectedSimulcastDestinations]
    );

    const twitchDests = generalForm.selectedSimulcastDestinations.filter(
        (d) => d.platform === PlatformId.Twitch
    );
    const fbDests = useMemo(
        () =>
            generalForm.selectedSimulcastDestinations.filter(
                (d) => d.platform === PlatformId.Facebook
            ),
        [generalForm.selectedSimulcastDestinations]
    );

    const videoPlayerDests = generalForm.selectedSimulcastDestinations.filter(
        (d) => d.platform === PlatformId.VideoPlayer
    );

    const showSaveToVL = useMemo(() => {
        if (enableVLH) {
            return (
                !videoPlayerDests.length &&
                !enableAutosave &&
                generalForm.selectedSimulcastDestinations.length > 1
            );
        } else {
            return !videoPlayerDests.length;
        }
    }, [
        videoPlayerDests,
        generalForm.selectedSimulcastDestinations.length,
        enableVLH,
        enableAutosave
    ]);

    const includesYt = !!ytDests.length;
    const includesFb = !!fbDests.length;
    const includesTwitch = !!twitchDests.length;
    const includesVideoPlayer = !!videoPlayerDests.length;

    const enableRecording = useMemo(() => {
        return (
            hasVideoPlayerAccess &&
            (!enableVLH || enableAutosave || includesVideoPlayer)
        );
    }, [hasVideoPlayerAccess, enableVLH, enableAutosave, includesVideoPlayer]);

    const scheduleOptions = [
        { text: t("platforms:schedule-live"), value: ScheduledOptions.Now },
        { text: t("platforms:schedule-later"), value: ScheduledOptions.Later }
    ];
    const isExistingAvailable =
        generalForm.selectedSimulcastDestinations.length -
            videoPlayerDests.length ===
            1 &&
        (includesFb || includesYt);
    if (isExistingAvailable) {
        scheduleOptions.push({
            text: t("platforms:schedule-existing"),
            value: ScheduledOptions.Existing
        });
    }
    const existingStreamsPlatform = includesFb
        ? PlatformId.Facebook
        : PlatformId.Youtube;

    const [initialState, setInitialState] = useState<PlatformInitialState>(
        {} as PlatformInitialState
    );

    const projId = user?.userInfo?.ProjectId;

    const onChange = (update: Partial<GeneralPlatformForm>) => {
        dispatch(updateGeneralForm(update));
    };

    // If Existing is selected, but not supported, reset it
    useEffect(() => {
        if (formState == ScheduledOptions.Existing && !isExistingAvailable) {
            dispatch(updateFormState(ScheduledOptions.Now));
        }
    }, [dispatch, formState, isExistingAvailable]);

    // Load any Existing posts from the social network
    useEffect(() => {
        const init = async () => {
            let state;
            if (includesFb) {
                state = await facebookInit({
                    destinationType: fbDests[0].type,
                    destinationId: fbDests[0].id
                });
            } else {
                // youtube
                state = await youtubeInit({
                    destinationType: PlatformId.Youtube,
                    destinationId: ytDests[0].id
                });
            }
            setInitialState(state);
        };
        if (isExistingAvailable) init();
    }, [
        isExistingAvailable,
        includesFb,
        youtubeInit,
        facebookInit,
        ytDests,
        fbDests
    ]);

    useEffect(() => {
        if (
            formState == ScheduledOptions.Existing &&
            !!initialState?.existingStreams?.[0]?.id
        ) {
            dispatch(
                updateSelectedStream({
                    id: existingStreamsPlatform,
                    stream: initialState.existingStreams[0]
                })
            );
        }
    }, [
        dispatch,
        formState,
        initialState?.existingStreams,
        existingStreamsPlatform
    ]);

    useEffect(() => {
        if (!generalForm.selectedSimulcastDestinations.length) {
            navigate("/platforms/simulcast/");
        }

        dispatch(
            updateGeneralForm({
                enableRecording,
                scheduledStartTime: (formState === ScheduledOptions.Later
                    ? dayjs().add(1, "hour")
                    : dayjs()
                ).toDate()
            })
        );
    }, [
        dispatch,
        navigate,
        generalForm.selectedSimulcastDestinations.length,
        formState,
        hasVideoPlayerAccess,
        enableRecording
    ]);

    useEffect(() => {
        if (!projects) return;

        const hasSwitcherPlayerEnabled =
            generalForm.selectedSimulcastDestinations.some(
                (dest) => dest.platform === PlatformId.VideoPlayer
            );
        const hasLiveShoppingEnabled =
            (videoplayer?.videoPlayerForm?.enableLiveShopping as boolean) ??
            false;
        const hasAddDescriptionLink =
            hasSwitcherPlayerEnabled &&
            hasLiveShoppingEnabled &&
            generalForm.selectedSimulcastDestinations.length > 1 &&
            !!projects[0]?.MicrositeUrl;

        const playerDesc =
            "Shop my livestream here: " +
            projects[0]?.MicrositeUrl +
            "?utm_source=switcher&utm_medium=social&utm_campaign=shoppableplayer";
        const containsText = generalForm.description.includes(playerDesc);

        let desc = generalForm.description;

        if (hasAddDescriptionLink) {
            if (containsText) return;

            if (desc > "") {
                if (desc.endsWith("\n")) {
                    desc = desc + playerDesc;
                } else {
                    desc = desc + "\n" + playerDesc;
                }
            } else {
                desc = playerDesc;
            }
        } else {
            if (containsText) {
                desc = desc.replace(playerDesc, "");

                if (desc.endsWith("\n"))
                    desc = desc.substring(0, desc.lastIndexOf("\n"));
            }
        }

        dispatch(updateGeneralForm({ description: desc }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, videoplayer, projects]);

    // Update the scheduled time if we have an existing stream
    useEffect(() => {
        if (formState !== ScheduledOptions.Existing) {
            return;
        }
        const facebookStream = selectedStreams?.facebook;
        const youTubeStream = selectedStreams?.youtube;

        if (!!facebookStream) {
            dispatch(
                updateGeneralForm({
                    scheduledStartTime: new Date(
                        facebookStream?.planned_start_time ??
                            facebookStream?.broadcast_start_time
                    )
                })
            );
            return;
        }

        if (!!youTubeStream && !!youTubeStream?.snippet?.scheduledStartTime) {
            dispatch(
                updateGeneralForm({
                    scheduledStartTime: new Date(
                        youTubeStream?.snippet?.scheduledStartTime
                    )
                })
            );
            return;
        }
    }, [dispatch, selectedStreams, formState]);

    const getDestinationsToPost = () => {
        // This was to support retry of failed posts.
        // in the current iteration this is not relevant and should
        // always return all selected dests, but keeping it around for later.

        // const failedRequests = completedRequests.filter(
        //   (r) => r.status === "rejected"
        // );
        // if (failedRequests?.length) {
        //   return generalForm.selectedSimulcastDestinations.filter((d) =>
        //     failedRequests.some((r) => r.destination.id === d.id)
        //   );
        // }

        return generalForm?.selectedSimulcastDestinations;
    };

    const createBroadcast = async (thumbnailAssetId) => {
        const isScheduled = formState === ScheduledOptions.Later;
        const isExisting = formState === ScheduledOptions.Existing;
        const broadcast: Broadcast = {
            Title: generalForm.title,
            Description: generalForm.description,
            ProjectId: projId,
            EnableRecording: generalForm.enableRecording,
            ThumbnailAssetId: thumbnailAssetId
        };

        if (isScheduled || isExisting) {
            broadcast.StartsAt = generalForm.scheduledStartTime.toISOString();
        }

        const hasSwitcherPlayerEnabled =
            generalForm.selectedSimulcastDestinations.some(
                (dest) => dest.platform === PlatformId.VideoPlayer
            );

        const hasLiveShoppingEnabled =
            (videoplayer?.videoPlayerForm?.enableLiveShopping as boolean) ??
            false;

        broadcast.EnableSwitcherPlayer = hasSwitcherPlayerEnabled;
        broadcast.EnableLiveShopping = hasLiveShoppingEnabled;

        const res = await client.broadcasts_PostBroadcast(broadcast, false);

        trackEvent("Event Created", {
            platform: "Simulcast",
            inDashboard: isInBrowser,
            broadcastId: res.Id,
            name: general.generalForm.title,
            scheduledForLater: isScheduled,
            scheduledTime: general.generalForm.scheduledStartTime.toISOString(),
            scheduledTimeFromNow: Math.floor(
                (general.generalForm.scheduledStartTime.getTime() -
                    new Date().getTime()) /
                    1000
            ),
            existingScheduled:
                general.selectedStreams?.facebook !== null ||
                general.selectedStreams?.youtube !== null,
            usedSpeedTest: general.generalForm.usedSpeedTest,
            coverImageProvided: !!general.generalForm.scheduledImageFile,
            collectionsSpecified:
                videoplayer?.videoPlayerForm?.playlistIds?.length > 0
        });

        return res;
    };

    const createPosts = async (broadcast: Broadcast) => {
        const externalProviders = [
            PlatformId.AmazonLive,
            PlatformId.Caast,
            PlatformId.Instafeed,
            PlatformId.MsStream,
            "custom"
        ];
        const destinations = getDestinationsToPost();
        let requests = [];

        for (let d of destinations) {
            if (externalProviders.includes(d.platform)) {
                const existing =
                    await client.switcherStreamSettings_GetSwitcherStreamSetting(
                        d.id
                    );
                delete existing["switcher-stream-setting-id"];
                existing.broadcastId = broadcast.Id;
                existing["is-direct-to-provider"] = false;
                existing["user-editable"] = false;
                requests.push(
                    client
                        .switcherStreamSettings_PostSwitcherStreamSetting(
                            existing
                        )
                        .then(
                            async (res) =>
                                await client.broadcasts_CreateOutput(
                                    broadcast.Id,
                                    res
                                )
                        )
                );
            } else if (d.platform === "youtube") {
                requests.push(
                    (general.selectedStreams.youtube === null
                        ? dispatch(
                              createYouTubeEvent({
                                  showNotification: false,
                                  swiBroadcastId: broadcast.Id,
                                  isSimulcast: true
                              })
                          )
                        : dispatch(
                              updateYoutubeEvent({
                                  swiBroadcastId: broadcast.Id,
                                  isSimulcast: true
                              })
                          )
                    ).then(
                        async (res) =>
                            await client.broadcasts_CreateOutput(
                                broadcast.Id,
                                res.payload
                            )
                    )
                );
            } else if (d.platform === "facebook") {
                requests.push(
                    (general.selectedStreams.facebook === null
                        ? dispatch(
                              createFacebookStream({
                                  broadcastId: broadcast.Id,
                                  selectedDest: {
                                      id: d.id,
                                      edge: d.type,
                                      name: d.name
                                  } as FacebookProfile,
                                  showNotification: false,
                                  isSimulcast: true
                              })
                          )
                        : dispatch(
                              updateFacebookStream({
                                  selectedDest: {
                                      id: d.id,
                                      edge: d.type,
                                      name: d.name
                                  } as FacebookProfile,
                                  isSimulcast: true
                              })
                          )
                    ).then(
                        async (res) =>
                            await client.broadcasts_CreateOutput(
                                broadcast.Id,
                                res.payload
                            )
                    )
                );
            } else if (d.platform === PlatformId.Twitch) {
                requests.push(
                    dispatch(
                        updateTwitchChannel({
                            broadcastId: broadcast.Id,
                            isSimulcast: true,
                            showNotification: false
                        })
                    ).then(async (res) => {
                        await client.broadcasts_CreateOutput(
                            broadcast.Id,
                            res.payload
                        );
                    })
                );
            } else if (d.platform === PlatformId.VideoPlayer) {
                requests.push(
                    dispatch(
                        updateVideoPlayerStream({
                            broadcast,
                            isSimulcast: true,
                            showNotification: false
                        })
                    )
                );
            }
        }

        return await Promise.allSettled(requests);
    };

    const processResults = (postResults) => {
        const requests = postResults.map((res, i) => ({
            destination: generalForm.selectedSimulcastDestinations[i],
            status: res?.status,
            reason: res?.reason
        }));
        const errors = requests.filter((r) => r?.status === "rejected");
        const allFailed =
            errors.length === generalForm.selectedSimulcastDestinations?.length;
        setCompletedRequests(requests);
        setAllPostsFailed(allFailed);

        return allFailed;
    };

    const dismissModal = (setting) => {
        if (
            switcherSdk.clientId !== "Generic" &&
            switcherSdk.api.versionMajor >= 2
        ) {
            return switcherSdk.terminateBCProfileCreation(setting);
        }
        dispatch(resetGeneralForm());
        navigate("/platforms");
    };

    const createSimulcastBroadcast = async () => {
        setIsCreateButtonDisabled(true);

        if (!generalForm.title.trim()) {
            return dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    // TODO: i18n
                    message: "errors:title-error"
                })
            );
        }

        if (includesFb && !generalForm.description.trim()) {
            return dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: "errors:description-error"
                })
            );
        }

        const thumbnailAssetId = generalForm.scheduledImageFile
            ? (await handleUploadThumbnail())?.Id
            : undefined;

        dispatch(setLoading(1));
        const broadcast = await createBroadcast(thumbnailAssetId);
        const input = await client.broadcasts_CreateInput(broadcast.Id);
        const postResults = await createPosts(broadcast);
        const isFailure = processResults(postResults);

        if (isFailure) {
            await client.broadcasts_DeleteBroadcast(broadcast.Id);
            dispatch(setLoading(-1));
            setShowModal(true);
            return;
        }

        if (videoplayer?.videoPlayerForm?.playlistIds?.length > 0) {
            const playlistPromises =
                videoplayer?.videoPlayerForm?.playlistIds.map(
                    async (playlistId) => {
                        if (!playlistId) return;

                        const playlistBroadcastResponse =
                            await client.videoPlayerPlaylist_PostVideoPlayerPlaylistBroadcastById(
                                playlistId,
                                broadcast.Id
                            );

                        if (
                            enablePayPerView &&
                            !!videoplayer?.videoPlayerForm?.creatorProductId
                        ) {
                            await client.creatorProductEntitlements_Create({
                                ProductEntitlements: [
                                    {
                                        ProductId:
                                            videoplayer?.videoPlayerForm
                                                ?.creatorProductId,
                                        VideoPlayerPlaylistBroadcastId:
                                            playlistBroadcastResponse
                                                ?.PlaylistBroadcast?.Id,
                                        Discriminator:
                                            CreatorProductEntitlementsBindingModelDiscriminator._2
                                    }
                                ]
                            });
                        }
                    }
                );

            await Promise.all(playlistPromises);
        }

        try {
            const setting: SimulcastSwitcherStreamSetting = {
                ...generalForm.selectedQuality.setting
            };
            setting["channel-name"] = generalForm.title;
            const simulcastSetting =
                await client.broadcasts_InitializeBroadcast(broadcast.Id, {
                    input,
                    setting
                });

            setSimulcastSetting(simulcastSetting);
            dispatch(setLoading(-1));
            setShowModal(true);
        } catch (e) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: `errors:${simulcastType}-configure-error`
                })
            );
            dispatch(setLoading(-1));
            // TODO: delete broadcast if init fails? Not ideal for scheduled posts where
            // we have already created posts on various platforms on behalf of user.
        }
        setIsCreateButtonDisabled(false);
    };

    const getModalMessage = () => {
        if (
            !allPostsFailed &&
            completedRequests.some((r) => r.status === "rejected")
        ) {
            return t(`messages:${simulcastType}-partial-configure-success`);
        }

        return allPostsFailed
            ? t(`errors:${simulcastType}-configure-error`)
            : t(`messages:${simulcastType}-configure-success`);
    };

    const updateScheduleState = (newState: ScheduledOptions) => {
        if (newState !== formState) {
            dispatch(updateFormState(newState));
        }
    };

    const onToggle = useCallback(
        (key: string) => {
            dispatch(
                updateGeneralForm({
                    ...generalForm,
                    [key]: !generalForm[key]
                })
            );
        },
        [generalForm, dispatch]
    );

    const onSpeedTestComplete = useCallback(
        (channel: ChannelSetting) => {
            dispatch(
                updateQuality({
                    name: `${channel["video-frame-height"]}p`,
                    setting: channel
                })
            );
        },
        [dispatch]
    );

    return (
        <>
            <SimulcastModal
                isOpen={showModal}
                setIsOpen={setShowModal}
                requests={completedRequests}
                successButton={allPostsFailed ? "Edit" : "Continue"}
                onSuccess={
                    allPostsFailed
                        ? () => setShowModal(false)
                        : () => dismissModal(simulcastSetting)
                }
                message={getModalMessage()}
                isSuccess={!allPostsFailed}
            />

            <FormWrapper
                destinationName={simulcastT("destination-name")}
                imageUrl={null}
            >
                <ConditionalScheduling
                    platformId={
                        isExistingAvailable
                            ? existingStreamsPlatform
                            : PlatformId.Simulcast
                    }
                    existingStreams={initialState?.existingStreams}
                    scheduleOptions={scheduleOptions}
                    selectedOption={formState}
                    setSelectedOption={updateScheduleState}
                    isSimulcast={true}
                />

                <TextInput
                    id="title"
                    type="text"
                    value={generalForm.title}
                    label={t("platforms:title")}
                    placeholder={t("platforms:title-placeholder")}
                    onChange={(e) => onChange({ title: e.target.value })}
                    maxLength={100}
                />

                <TextArea
                    id="description"
                    value={generalForm.description}
                    label={t("platforms:event-description")}
                    placeholder={t("platforms:event-description")}
                    rows={5}
                    maxLength={5000}
                    onChange={(e) => onChange({ description: e.target.value })}
                />

                <div className="row mt-2 mb-2">
                    <SpeedTest
                        onComplete={onSpeedTestComplete}
                        hasFullHD={
                            user?.userInfo?.FeatureClaims?.indexOf("1080p") !==
                            -1
                        }
                    />
                </div>

                <QualitySelector />

                {showSaveToVL && (
                    <div className="form-group">
                        <Toggle
                            label={simulcastT("enable-recording")}
                            on={generalForm.enableRecording}
                            onToggle={() => onToggle("enableRecording")}
                        />
                    </div>
                )}

                <div className="form-group">
                    <label>Selected Destinations</label>
                    <SimulcastDestinationDisplay isInline={true} />
                </div>

                {(includesFb || includesYt) && (
                    <label>{simulcastT("destination-options")}</label>
                )}
                <Accordion defaultActiveKey={[]} alwaysOpen>
                    {includesFb &&
                        fbDests.map((d, i) => {
                            return (
                                <Accordion.Item eventKey={d.id} key={i}>
                                    <Accordion.Header>
                                        <FacebookIcon />{" "}
                                        <span
                                            className={
                                                styles["accordion-header-title"]
                                            }
                                        >{`${titleCase(d.platform)} - ${
                                            d.name
                                        }`}</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <FacebookForm
                                            showSpeedtest={false}
                                            destination={
                                                {
                                                    name: d.name,
                                                    id: d.id,
                                                    edge: d.type,
                                                    isTestStream: d.isTestStream
                                                } as FacebookProfile
                                            }
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}

                    {includesYt &&
                        ytDests.map((d, i) => {
                            return (
                                <Accordion.Item eventKey={d.id} key={i}>
                                    <Accordion.Header>
                                        <YouTubeIcon />{" "}
                                        <span
                                            className={
                                                styles["accordion-header-title"]
                                            }
                                        >{`${titleCase(d.platform)} - ${
                                            d.name
                                        }`}</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <YoutubeForm destinationId={d.id} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}

                    {includesTwitch &&
                        twitchDests.map((d, i) => {
                            return (
                                <Accordion.Item eventKey={d.id} key={i}>
                                    <Accordion.Header>
                                        {/* twitch icon */}
                                        <span
                                            className={
                                                styles["accordion-header-title"]
                                            }
                                        >{`${titleCase(d.platform)} - ${
                                            d.name
                                        }`}</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <TwitchForm multiForm={true} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}

                    {includesVideoPlayer &&
                        videoPlayerDests.map((d, i) => {
                            return (
                                <Accordion.Item eventKey={d.id} key={i}>
                                    <Accordion.Header>
                                        <SwitcherLogo />
                                        <span
                                            className={
                                                styles["accordion-header-title"]
                                            }
                                        >
                                            {hasCatalogClaim
                                                ? t("breadcrumbs:catalog")
                                                : d.name}
                                        </span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <VideoPlayerForm />
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                </Accordion>
            </FormWrapper>

            <div className={styles["btn-container"]}>
                <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() =>
                        navigate("/platforms/simulcast/destinations")
                    }
                >
                    &#60; Change Destinations
                </button>

                <button
                    type="button"
                    onClick={createSimulcastBroadcast}
                    disabled={isCreateButtonDisabled}
                    className={`btn btn-primary ${styles["save-btn"]}`}
                >
                    {t("buttons:create")}
                </button>
            </div>
        </>
    );
};
